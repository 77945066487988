<template>
  <v-container
    fluid
    tag="section"
  >

<base-material-card
      icon="mdi-check"
      title="Contratos Cancelados"
      class="px-5 py-3"
    >


   <v-dialog 
      title="Mensagem" 

    >

    <v-container >
      <c-row class="justify-content-center">
              <v-form>
                  {{ msg_dialog }}

              </v-form>
      </c-row>
    </v-container>  

    </v-dialog>

      <v-dialog
        v-model="dialog"
        width="500"
      >

        <v-card>
            <v-toolbar  text
                color="success"
                   dark > 
                <h3> Mensagem</h3>
             </v-toolbar>



          <v-card-text>
            {{ msg_dialog }}
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-simple-table>
        <thead>
          <tr>
          <th style="width: 30%;font-size:14px;font-weight: bold;">
              Nome
            </th>
          <th style="width: 20%;font-size:14px;font-weight: bold;">
              Plano
            </th>
 
          <th style="width: 10%;font-size:14px;font-weight: bold;">
              Data Aprovação
            </th>
          <th style="width: 15%;font-size:14px;font-weight: bold;">
              Usuário
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in contratos"
            :key="item.id"
          >
            <td>{{ item.nome }}</td>
            <td>{{ item.nome_plano }}</td>
            <td>{{ item.dataStatus }}</td>
            <td>{{ item.userStatus }}</td>
            <td style="width: 15%;">


           

            <v-btn color="orange" @click="recupera(item, index)" :loading="loading3" small>
                  Recuperar
               <v-icon right dark>mdi-check</v-icon>      
            </v-btn>
           

            </td>
          </tr>
        </tbody>
      </v-simple-table>
  </base-material-card>
  </v-container>
</template>
<script>
  import Contrato from '../../../services/contrato'
  import axios from 'axios'
  import authHeader from '../../../services/auth-header';
  import config  from '../../../services/config'


  export default {


     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    data: () => ({
      contratos: [],
      msg_dialog: '',
      dialog: false,
      loading3: false,
    }),

    mounted () {
      this.listar()
    },

    methods: {
      listar () {
        Contrato.lista('Previda', 'CANCELADO').then(response => {
            this.contratos = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {

        })
      },
      recupera(item, index){
            
            this.loading2 = true,
            Contrato.recupera(item.id).then(response => {
            this.contratos.splice(index, 1)
            this.dialog = true
            this.status_msg = 'success'
            this.msg_dialog = 'Contrato Recuperado com Sucesso'

        }).catch(e => {
            this.loading2 = false

        }).finally(() => {
            this.loading2 = false

        })
      },
    }
      

  }

</script>
